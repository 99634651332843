import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SqCarousel = _resolveComponent("SqCarousel");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_SqCarousel, {
    data: [{
      id: 1,
      url: 'https://img1.baidu.com/it/u=3036729203,1943492228&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500',
      title: '没有第三方的信任',
      content: '区块链的分散机制允许每个对等节点拥有不可更改且防篡改的完整数据。结果，用户之间的交互是可信且可追溯的。'
    }, {
      id: 1,
      url: 'https://img1.baidu.com/it/u=3036729203,1943492228&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500',
      title: '没有第三方的信任',
      content: '区块链的分散机制允许每个对等节点拥有不可更改且防篡改的完整数据。结果，用户之间的交互是可信且可追溯的。'
    }, {
      id: 1,
      url: 'https://img1.baidu.com/it/u=3036729203,1943492228&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500',
      title: '没有第三方的信任',
      content: '区块链的分散机制允许每个对等节点拥有不可更改且防篡改的完整数据。结果，用户之间的交互是可信且可追溯的。'
    }, {
      id: 1,
      url: 'https://img1.baidu.com/it/u=3036729203,1943492228&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500',
      title: '没有第三方的信任',
      content: '区块链的分散机制允许每个对等节点拥有不可更改且防篡改的完整数据。结果，用户之间的交互是可信且可追溯的。'
    }, {
      id: 1,
      url: 'https://img1.baidu.com/it/u=3036729203,1943492228&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500',
      title: '没有第三方的信任',
      content: '区块链的分散机制允许每个对等节点拥有不可更改且防篡改的完整数据。结果，用户之间的交互是可信且可追溯的。'
    }, {
      id: 1,
      url: 'https://img1.baidu.com/it/u=3036729203,1943492228&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500',
      title: '没有第三方的信任',
      content: '区块链的分散机制允许每个对等节点拥有不可更改且防篡改的完整数据。结果，用户之间的交互是可信且可追溯的。'
    }],
    line: 4,
    multiple: true
  }, null, 8, ["data"])]);
}