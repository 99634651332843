import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    radius: {
      type: String
    }
  },

  setup() {
    return {};
  }

});