import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-607076bb"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "carousel"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", {
    style: _normalizeStyle({
      'flex-wrap': `${_ctx.multiple ? 'wrap' : 'nowrap'}`
    }),
    id: "nav"
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
    return _openBlock(), _createElementBlock("li", {
      style: _normalizeStyle({
        width: `${100 / _ctx.line}%`
      }),
      key: item.id
    }, [_createElementVNode("img", {
      src: item.url,
      alt: ""
    }, null, 8, _hoisted_2), _createElementVNode("p", _hoisted_3, _toDisplayString(item.title), 1), _createElementVNode("p", {
      class: "content",
      innerHTML: item.content
    }, null, 8, _hoisted_4)], 4);
  }), 128))], 4)]);
}