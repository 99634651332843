import { defineComponent } from 'vue';
import ProductDisplay from '@/components/productDisplay.vue';
import SqCarousel from '@/components/sqCarousel.vue';
import SqIntroduce from '@/components/sqIntroduce.vue';
export default defineComponent({
  components: {
    ProductDisplay,
    SqCarousel,
    SqIntroduce
  },

  setup() {
    return {};
  }

});