import { defineComponent, onMounted } from "vue";
export default defineComponent({
  props: {
    data: {},
    line: {
      type: Number,
      default: 4
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },

  setup(props) {
    const methods = {
      // 初始化与绑定监听事件方法
      scrollInit() {
        // 获取要绑定事件的元素
        const nav = document.getElementById("nav");
        let flag; // 鼠标按下

        let downX; // 鼠标点击的x下标

        let scrollLeft; // 当前元素滚动条的偏移量

        nav.addEventListener("mousedown", function (event) {
          flag = true;
          downX = event.clientX; // 获取到点击的x下标

          scrollLeft = nav.scrollLeft; // 获取当前元素滚动条的偏移量
        });
        nav.addEventListener("mousemove", function (event) {
          if (flag) {
            // 判断是否是鼠标按下滚动元素区域
            let moveX = event.clientX; // 获取移动的x轴

            let scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离

            nav.scrollLeft = scrollLeft - scrollX; // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
          }
        }); // 鼠标抬起停止拖动

        nav.addEventListener("mouseup", function () {
          flag = false;
        }); // 鼠标离开元素停止拖动

        nav.addEventListener("mouseleave", function (event) {
          flag = false;
        });
      }

    };
    onMounted(() => {
      // 当行调用事件
      if (!props.multiple) methods.scrollInit();
    });
    return { ...methods
    };
  }

});