import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-f966915a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "text"
};
const _hoisted_2 = {
  class: "display-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "display",
    style: _normalizeStyle({
      'background-color': `${_ctx.backgroundColor}`
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("p", null, _toDisplayString(_ctx.title), 1), _createElementVNode("p", null, _toDisplayString(_ctx.subtitle), 1)]), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 4);
}