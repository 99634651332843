import { defineComponent } from 'vue';
import { imgUrl } from "@/utils/urlManage";
export default defineComponent({
  props: {
    subtitle: {
      type: String
    },
    title: {
      type: String
    },
    backgroundColor: {
      type: String,
      default: '#e4e4e4'
    }
  },

  setup() {
    return {
      imgUrl
    };
  }

});